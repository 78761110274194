import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import moment from 'moment';
import { BiMinus, BiPlus } from 'react-icons/bi';
import { setLoader } from '../store/reducer';
import { AxiosApi } from '../utility/axios';
import NoDataAvailable from '../Components/NoDataAvailable';
import { FaFilter } from 'react-icons/fa';
import TablePaginate from '../Components/TablePaginate';

const UserTransactionHistory = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);

  const [transactionList, setTransactionList] = useState([]);
  const [transactionLength, setTransactionLength] = useState(0);
  const [radioValue, setRadioValue] = useState("all");

  const handlePageClick = (limit, offset) => {
    if (offset >= 0) {
      let type = radioValue;
      if (radioValue === "all") {
        type = "";
      }
      handleGetUserTransactions(type, limit, offset);
    }
  };

  useEffect(() => {
    if (params && params.userId) {
      setRadioValue("all");
      handleGetUserTransactions("", 10, 0);
    }
  }, []);

  const handleGetUserTransactions = (type, limit, offset) => {
    dispatch(setLoader(true));
    setTransactionLength(0);
    setTransactionList([]);
    const url = `${apiBaseUrl}/api/user/credit-transactions/${params.userId}?type=${type}&limit=${limit}&offset=${offset}`;
    AxiosApi.get(url).then((response) => {
      setTransactionLength(response.data?.length);
      setTransactionList(response.data?.data);
      dispatch(setLoader(false));
    }).catch((error) => {
      dispatch(setLoader(false));
    });
  };

  const handleOnChangeTransactionList = (val) => {
    setRadioValue(val);
    let type = val;
    if (val === "all") {
      type = "";
    }

    handleGetUserTransactions(type, 10, 0);
  };

  const actionBodyTemplate = (item) => {
    if (item?.credit && item?.credit > 0) {
      return "-";
    }

    let articleHref = ``;
    if (item.type === "1-Click Blog") {
      articleHref = `/history-details/${item.article_id}`;
    } else if (item.type === "Blog Co-Pilot") {
      articleHref = `/article-optimized-blog/${item.article_id}`;
    } else if (item.type === "Social Media Post Writer") {
      articleHref = `/social-media-post/${item.article_id}`;
    } else if (item.type === "NewsLetters") {
      articleHref = `/news-letters/${item.article_id}`;
    } else if (item.type === "Azgo Newsletter") {
      articleHref = `/azgo-newsletter/${item.article_id}`;
    } else if (item.type === "CMI NewsLetters") {
      articleHref = `/cmi-newsletter/${item.article_id}`;
    } else if (item.type === "Press Release") {
      articleHref = `/press-release/${item.article_id}`;
    } else if (item.type === "E-Commerce Blog") {
      articleHref = `/e-commerce/${item.article_id}`;
    } else if (item.type === "PDF To Article") {
      articleHref = `/history-details/${item.article_id}`;
    } else if (item.type === "Imda Post Writer") {
      articleHref = `/imda-post/${item.article_id}`;
    }

    return (
      <Link to={articleHref} target="_blank" className="view-link">
        View
      </Link>
    );
  };

  return (
    <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="mb-0">User Credit Transaction History ( {transactionLength} )</h4>
        <Dropdown data-bs-theme="dark">
          <Dropdown.Toggle id="dropdown-button-dark-example1" variant="primary" className="">
            <FaFilter /> <span className="mx-2">Transaction Filter</span>
          </Dropdown.Toggle>

          <Dropdown.Menu className='w-100'>
            {[
              // { label: "Blog Credits", value: "Blog" },
              // { label: "Social Credits", value: "Social" },
              { label: "Credit Transactions", value: "Credit" },
              { label: "Debit Transactions", value: "Debit" },
            ].map((item, index) => (
              <Dropdown.Item as="button" key={index} className="my-1 py-2"
                onClick={() => handleOnChangeTransactionList(item.value)}
                active={item.value === radioValue ? true : false}
              >
                {item.label}
              </Dropdown.Item>
            ))}
            <Dropdown.Divider />
            <Dropdown.Item as="button" onClick={() => handleOnChangeTransactionList("all")}>Clear Filter</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="res-table">
        <table className="custom-table transaction-list-table table mt-20 mb-4">
          <thead className="">
            <tr>
              <th scope="col">Type</th>
              <th scope="col">Sub Type</th>
              <th scope="col">Debit / Credit</th>
              <th scope="col">Balance</th>
              <th scope="col">Article Id</th>
              <th scope="col">Date</th>
              <th scope="col">Comment</th>
              <th scope="col">Refund Status</th>
            </tr>
          </thead>
          <tbody>
            {transactionList && transactionList.length > 0 ? (
              <React.Fragment>
                {transactionList.map((item, index) => (
                  <tr key={index}>
                    <td className="">{item?.type}</td>
                    <td className="">{item?.sub_type || "-"}</td>
                    <td className="">
                      <div className="d-flex align-items-center">
                        <span style={{ width: "24px" }}>{item?.credit > 0 ? <BiPlus color='#00ab55' className='fs-5' /> : <BiMinus color='#e7515a' className='fs-5' />}</span>
                        {" "} {item?.credit ? item.credit : item?.debit}
                      </div>
                    </td>
                    <td className="">{item?.balance} Addlly Credit</td>
                    <td className="text-center">{actionBodyTemplate(item)}</td>
                    <td className="">
                      <h6>{moment(item?.created_at).format("MMM DD YYYY - h:mm a")}</h6>
                    </td>
                    <td className="">
                      <h6 className="text-width" data-tooltip-id="my-tooltip" data-tooltip-content={item?.comment} data-tooltip-place="bottom">
                        {item?.comment || "-"}
                      </h6>
                    </td>
                    <td className="text-center">
                      {item?.refund_status ? (
                        item?.refund_status?.charAt(0).toUpperCase() + item?.refund_status?.slice(1)
                      ) : "-"}
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ) : (
              <tr>
                <td colSpan="7" className="text-center">
                  <NoDataAvailable />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <TablePaginate length={transactionLength} handlePageChange={handlePageClick} />
    </div>
  );
};

export default UserTransactionHistory;
