import React, { useEffect, useState } from 'react';
import Swal from "sweetalert";
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { toast } from "react-hot-toast";
import { setLoader } from '../store/reducer';
import { AxiosApi } from '../utility/axios';
import TablePaginate from "../Components/TablePaginate";
import NoDataAvailable from '../Components/NoDataAvailable';

const UserLog = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);
  const loginDetails = useSelector(({ loginDetails }) => loginDetails);

  const [creditsInfo, setCreditsInfo] = useState({});
  const [articleHistory, setArticleHistory] = useState([]);
  const [searchTxt, setSearchTxt] = useState("");
  const [itemOffset, setItemOffset] = useState(0);
  const [socialSummaryUser, setSocialSummaryUser] = useState(false);
  const [userDetail, setUserDetail] = useState(false);

  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;
  const filterData = articleHistory && articleHistory.length > 0 ? (
    articleHistory.filter((item) => {
      return item?.topic?.toLocaleLowerCase().search(searchTxt.toLocaleLowerCase()) >= 0 ||
        item?.keyword?.toLocaleLowerCase().search(searchTxt.toLocaleLowerCase()) >= 0 ||
        item?.display_type?.toLocaleLowerCase().search(searchTxt.toLocaleLowerCase()) >= 0 ||
        item?.AI_type?.toLocaleLowerCase().search(searchTxt.toLocaleLowerCase()) >= 0 ||
        item?.lan?.toLocaleLowerCase().search(searchTxt.toLocaleLowerCase()) >= 0;
    }).slice(itemOffset, endOffset)
  ) : [];

  const filterData4 =
    socialSummaryUser && socialSummaryUser.length > 0 &&
    socialSummaryUser.filter(
      (item) => (item?.first_name || "").toLocaleLowerCase().search((searchTxt || "").toLocaleLowerCase()) >= 0
    );

  useEffect(() => {
    setSearchTxt("");
    if (params && params.userId) {
      dispatch(setLoader(true));
      const url = `${apiBaseUrl}/api/user/get-credits-available/${params.userId}`;
      AxiosApi.get(url).then((res) => {
        setCreditsInfo(res.data[0]);

        const url = `${apiBaseUrl}/api/user/get-all-summary/${params.userId}`;
        AxiosApi.get(url).then((res) => {
          if (res && res.data) {
            setSocialSummaryUser(res.data.socialSummary);
          }
        });

        AxiosApi.get(`${apiBaseUrl}/user/detail/${params.userId}`).then((response) => {
          setUserDetail(response.data);
          dispatch(setLoader(false));
        }).catch((error) => {
          dispatch(setLoader(false));
          toast.error("Something went wrong.");
          console.log(error);
        });

        dispatch(setLoader(false));
      }).catch((error) => {
        dispatch(setLoader(false));
      });

      handleGetAllArticleHistory(params.userId);
    }
  }, []);

  const handleGetAllArticleHistory = async (userId) => {
    try {
      setArticleHistory([]);
      const response = await AxiosApi.get(`${apiBaseUrl}/api/article-post-history/list-by-user/${userId}`);
      dispatch(setLoader(false));
      if (response && response.data && response.data.data) {
        setArticleHistory(response.data.data);
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error("Something went wrong.");
      console.log(error);
    }
  };

  const handleChangePageLoadData = (limit, offset) => {
    if (offset >= 0) {
      setItemOffset(offset);
    }
  };

  const topicBodyTemplate = (item) => {
    return (
      <React.Fragment>
        <h6 className="text-width" data-tooltip-id="my-tooltip" data-tooltip-content={item?.topic} data-tooltip-place="bottom">
          {item?.topic}
        </h6>
        <p className="text-width" data-tooltip-id="my-tooltip" data-tooltip-content={item?.keyword} data-tooltip-place="bottom">
          {item?.keyword || "-"}
        </p>
      </React.Fragment>
    );
  };

  const statusBodyTemplate = (item) => {
    let backgroundColor = "", status = "";
    if (item.status === "completed") {
      backgroundColor = "#84CC16";
      status = "Done";
    } else if (item.status === "pending" && item.type !== "short_flow") {
      backgroundColor = "#F59E0B";
      status = "In Progress";
    } else if (item.status === "pending") {
      backgroundColor = "#E7E721";
      status = "Processing ...";
    } else if (item.status === "processing") {
      backgroundColor = "#F59E0B";
      status = "In Progress";
    } else if (item.status === "notAcceptable") {
      backgroundColor = "#EF4444";
      status = "Not Acceptable";
    } else {
      backgroundColor = "#EF4444";
      status = "Error";
    }

    return (
      <div className="d-flex align-items-center gap-2">
        <div className="dotStatus">
          <span className={`d-block`} style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: backgroundColor }} />
        </div> {status}
      </div>
    );
  };

  const actionBodyTemplate = (item) => {
    let articleHref = ``;
    if (item.display_type === "1-Click Blog Writer") {
      articleHref = `/history-details/${item.id}`;
    } else if (item.display_type === "SEO Blog Co-Pilot") {
      articleHref = `/article-optimized-blog/${item.id}`;
    } else if (item.display_type === "Social Media Post Writer") {
      articleHref = `/social-media-post/${item.id}`;
    } else if (item.display_type === "Newsletter Builder") {
      articleHref = `/news-letters/${item.id}`;
    } else if (item.display_type === "Azgo Newsletter") {
      articleHref = `/azgo-newsletter/${item.id}`;
    } else if (item.display_type === "CMI Newsletter") {
      articleHref = `/cmi-newsletter/${item.id}`;
    } else if (item.display_type === "Press Release Quick Draft") {
      articleHref = `/press-release/${item.id}`;
    } else if (item.display_type === "E-Commerce Blog Writer") {
      articleHref = `/e-commerce/${item.id}`;
    } else if (item.display_type === "PDF To Article") {
      articleHref = `/history-details/${item.id}`;
    } else if (item.display_type === "Invest Idea") {
      articleHref = `/invest-idea-history/${item.id}`;
    } else if (item.display_type === "Rysense Blog") {
      articleHref = `/happydot-blog/${item.id}`;
    } else if (item.display_type === "HappyDot Blog") {
      articleHref = `/happydot-blog/${item.id}`;
    } else if (item.display_type === "Imda Post Writer") {
      articleHref = `/imda-post/${item.id}`;
    }

    return (
      <Dropdown align="end" className="actionDropdown" onClick={(e) => e.stopPropagation()}>
        <Dropdown.Toggle id="dropdown-basic">
          <BsThreeDots />
        </Dropdown.Toggle>
        {articleHref && articleHref !== "" && (
          <Dropdown.Menu>
            <Dropdown.Item target="_blank" href={articleHref} rel="noreferrer">
              View <AiOutlineEye />
            </Dropdown.Item>
          </Dropdown.Menu>
        )}
      </Dropdown>
    );
  };

  const handleNavigateToViewArticle = (item) => {
    let articleHref = ``;
    if (item.display_type === "1-Click Blog Writer") {
      articleHref = `/history-details/${item.id}`;
    } else if (item.display_type === "SEO Blog Co-Pilot") {
      articleHref = `/article-optimized-blog/${item.id}`;
    } else if (item.display_type === "Social Media Post Writer") {
      articleHref = `/social-media-post/${item.id}`;
    } else if (item.display_type === "Newsletter Builder") {
      articleHref = `/news-letters/${item.id}`;
    } else if (item.display_type === "Azgo Newsletter") {
      articleHref = `/azgo-newsletter/${item.id}`;
    } else if (item.display_type === "CMI Newsletter") {
      articleHref = `/cmi-newsletter/${item.id}`;
    } else if (item.display_type === "Press Release Quick Draft") {
      articleHref = `/press-release/${item.id}`;
    } else if (item.display_type === "E-Commerce Blog Writer") {
      articleHref = `/e-commerce/${item.id}`;
    } else if (item.display_type === "PDF To Article") {
      articleHref = `/history-details/${item.id}`;
    } else if (item.display_type === "Invest Idea") {
      articleHref = `/invest-idea-details/${item.id}`;
    } else if (item.display_type === "Rysense Blog") {
      articleHref = `/happydot-blog/${item.id}`;
    } else if (item.display_type === "HappyDot Blog") {
      articleHref = `/happydot-blog/${item.id}`;
    } else if (item.display_type === "Imda Post Writer") {
      articleHref = `/imda-post/${item.id}`;
    }

    window.open(articleHref, "_blank");
  };

  const calculateSum = (data) => {
    return data.reduce((total, item) => total + item.blog_used, 0);
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-6">
          <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
            <div className="d-flex align-items-center justify-content-between">
              <h4 className="mb-0">{userDetail.first_name}</h4>
            </div>
            <div className="res-table">
              <table className="custom-table transaction-list-table table mt-20 mb-4">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th style={{ textAlign: 'right' }}>Addlly Credits</th>
                    {/* <th style={{ textAlign: 'right' }}>Blog</th>
                    <th style={{ textAlign: 'right' }}>Social</th>
                    <th style={{ textAlign: 'right' }}>Review</th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className=""><b>Total Credits</b></td>
                    <td style={{ textAlign: 'right' }}><b>{(creditsInfo.totalUsedCredits || 0) + (creditsInfo.credits || 0)}</b></td>
                    {/* <td style={{ textAlign: 'right' }}>-</td>
                    <td style={{ textAlign: 'right' }}>-</td>
                    <td style={{ textAlign: 'right' }}>-</td> */}
                  </tr>
                  <tr>
                    <td className="">Credits Used</td>
                    <td style={{ textAlign: 'right' }}>{creditsInfo.totalUsedCredits || 0}</td>
                    {/* <td style={{ textAlign: 'right' }}>{creditsBlogUsed || "-"}</td>
                    <td style={{ textAlign: 'right' }}>{creditsSocialUsed || "-"}</td>
                    <td style={{ textAlign: 'right' }}>{creditsReviewUsed || "-"}</td> */}
                  </tr>
                  <tr>
                    <td className="">Credits Available</td>
                    <td style={{ textAlign: 'right' }}>{creditsInfo.credits || 0}</td>
                    {/* <td style={{ textAlign: 'right' }}>-</td>
                    <td style={{ textAlign: 'right' }}>-</td>
                    <td style={{ textAlign: 'right' }}>-</td> */}
                  </tr>
                  {creditsInfo && creditsInfo.isOrgUser && (
                    <tr>
                      <td className="">Used By This User</td>
                      <td style={{ textAlign: 'right' }}>{creditsInfo.userUsedCredits || 0}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {creditsInfo && creditsInfo.orgUserInfo && creditsInfo.orgUserInfo.length > 0 && (
          <div className="col-md-6">
            <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="mb-0">Credits Used Summary</h4>
              </div>
              <div className="res-table">
                <table className="custom-table transaction-list-table table mt-20 mb-4">
                  <thead>
                    <tr>
                      <th scope="col">User Name</th>
                      <th style={{ textAlign: 'right' }}>Used Addlly Credits</th>
                    </tr>
                  </thead>
                  <tbody>
                    {creditsInfo.orgUserInfo.map((user, index) => (
                      <tr key={index}>
                        <td className="">{user.first_name || ""} {user.last_name || ""}</td>
                        <td style={{ textAlign: 'right' }}>{user.blog_used || 0}</td>
                      </tr>
                    ))}
                    <tr>
                      <td className=""><b>Total Credits</b></td>
                      <td style={{ textAlign: 'right' }}><b>{calculateSum(creditsInfo.orgUserInfo)}</b></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        {/* <div className="col-md-6">
          <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
            <div className="d-flex align-items-center justify-content-between">
              <h4 className="mb-0">Social Media Posts - {filterData4.length || 0}</h4>
            </div>
            <div className="res-table">
              {filterData4.length > 0 ? (
                <table className="custom-table table mt-20 mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Post Type</th>
                      <th scope="col">Total Posts</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterData4 && filterData4.length > 0 && filterData4?.map((item, ind) => (
                      <tr key={ind}>
                        <td>
                          <div>{item?.post_type}</div>
                        </td>
                        <td>
                          <div>{item?.ttl}</div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 className="text-center mt-3 mb-4 color-Dark-dark_4">Your recent Social media usage distribution will appear here</h3>
              )}
            </div>
          </div>
        </div> */}
      </div>

      <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4 mt-4">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="mb-0">Article History ( {articleHistory?.length || 0} )</h4>
          <div>
            <div className="input-group">
              <input placeholder="Search Name..." type="text" className="form-control" onChange={(e) => setSearchTxt(e.target.value)} />
            </div>
          </div>
        </div>
        <div className="res-table">
          <DataTable value={filterData} className="table" emptyMessage={<NoDataAvailable />}
            selectionMode="single" dataKey="id" onSelectionChange={(e) => handleNavigateToViewArticle(e.value)}
          >
            <Column header="Id" field="id"></Column>
            <Column header="Article Details" sortField="topic" body={topicBodyTemplate} sortable></Column>
            <Column header="Type" sortField="display_type" sortable body={(item) => <div>{item?.display_type || "-"}</div>}></Column>
            <Column header="Last modified" sortField="created_at" sortable body={(item) => moment(item?.created_at).format("MMM DD YYYY - h:mm a")}></Column>
            <Column header="Ai Type" sortField="AI_type" sortable body={(item) => (item?.AI_type || "-")}></Column>
            <Column header="status" sortField="status" sortable body={statusBodyTemplate}></Column>
            <Column header="Language" sortField="lan" sortable body={(item) => <div>{item?.lan}</div>}></Column>
            <Column header="Action" body={actionBodyTemplate}></Column>
          </DataTable>
        </div>
        <TablePaginate length={articleHistory?.length} handlePageChange={handleChangePageLoadData} />
      </div>
    </React.Fragment>
  );
};

export default UserLog;
