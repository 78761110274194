import React from 'react';
import { getUser } from "../utility/apiService";
import Select, { components } from 'react-select';
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';
import moment from 'moment';
import { BiMinus, BiPlus } from 'react-icons/bi';
import { setLoader } from '../store/reducer';
import { AxiosApi } from '../utility/axios';
import NoDataAvailable from '../Components/NoDataAvailable';
import TablePaginate from '../Components/TablePaginate';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { BsThreeDots } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { toast } from "react-hot-toast";

const CreditUsage = () => {
    const [showInfo, setShowInfo] = useState(false);
    const dispatch = useDispatch();
    const userList = useSelector(({ userList }) => userList);
    const [userNames, setUserNames] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [creditDetails, setCreditDetails] = useState({ total: 0, used: 0, remaining: 0 });
    const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);
    const [search, setSearch] = useState(1);
    const [isOrgUser, setIsOrgUser] = useState(false);
    const [counts, setCounts] = useState({
        blog: 0,
        social: 0,
        newsletter: 0,
        others: 0
    });

    const formatUserList = (users) => {
        return users.map(item => ({
            id: item.id,
            first_name: item.first_name || '',
            last_name: item.last_name || '',
            company: item.company || '',
            username: item.username || '',
            name: `${item.first_name || ''} ${item.last_name || ''}`,
            email: item.email || '',
            created_at: item.created_at,
            phone_number: item.phone_number || '',
            shopify_domain: item.shopify_domain || '',
            role: item.role || ''
        }));
    };

    useEffect(() => {
        if (selectedUser !== null && selectedUser.role === "OrgAdmin"){
            setIsOrgUser(true);
        } else {
            setIsOrgUser(false);
        }
    },[selectedUser]);

    useEffect(() => {
        dispatch(getUser(""));
    }, [dispatch]);

    useEffect(() => {
        if (userList && userList.length > 0) {
            const tempUserNames = formatUserList(userList);
            setUserNames([...tempUserNames]);
        }
    }, [userList]);

    const handleSearch = async () => {
        dispatch(setLoader(true));
        const res2 = await AxiosApi.get(`${apiBaseUrl}/api/user/get-credits-available/${selectedUser.id}`);
        if (res2 && res2.data) {
            setCreditDetails({
                total: res2.data[0].totalUsedCredits + res2.data[0].credits,
                used: res2.data[0].totalUsedCredits,
                remaining: res2.data[0].credits,
                userUsedCredits: res2.data[0].userUsedCredits,
                isOrgUser: res2.data[0].isOrgUser
            });
            setSearch(search + 1);
        }
        setShowInfo(true);
        dispatch(setLoader(false));
    };

    const customFilter = (option, inputValue) => {
        const { first_name, last_name, username, company } = option.data;
        const searchValue = inputValue.toLowerCase();
        const safeFirstName = first_name?.toLowerCase() || "";
        const safeLastName = last_name?.toLowerCase() || "";
        const safeUsername = username?.toLowerCase() || "";
        const safeCompany = company?.toLowerCase() || "";
        const safeFullName = `${safeFirstName} ${safeLastName}`;

        return (
            safeFirstName.includes(searchValue) ||
            safeLastName.includes(searchValue) ||
            safeUsername.includes(searchValue) ||
            safeFullName.includes(searchValue) ||
            safeCompany.includes(searchValue)
        );
    };

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };

    const CustomMenuList = ({ children, ...props }) => (
        <components.MenuList {...props}>
            <div className="dropdown-header">
                <div className="header-item">Name</div>
                <div className="header-item">UID</div>
                <div className="header-item">Company</div>
            </div>
            {children}
        </components.MenuList>
    );

    const CustomOption = ({ children, ...props }) => (
        <components.Option {...props}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ flex: '1' }}>{props.data.first_name} {props.data.last_name}</div>
                <div style={{ flex: '1' }}>{props.data.username}</div>
                <div style={{ flex: '1' }}>{props.data.company}</div>
            </div>
        </components.Option>
    );

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            padding: 0,
            width: '750px',
        }),
        control: (provided) => ({
            ...provided,
            width: '750px',
            height: '44px',
            borderRadius: '5px',
            borderColor: '#ccc',
            boxShadow: 'none',
            cursor: 'pointer'
        }),
    };

    return (
        <React.Fragment>
            <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
                <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                    <h3>Client's usage History</h3>
                    <div className="d-flex flex-wrap align-items-center gap-4">
                        <Select
                            options={userNames}
                            styles={customStyles}
                            getOptionLabel={(e) => `${e.first_name} ${e.last_name}`}
                            getOptionValue={(e) => e.username}
                            components={{
                                Option: CustomOption,
                                MenuList: CustomMenuList
                            }}
                            filterOption={customFilter}
                            onChange={(e) => { setSelectedUser(e); }}
                            placeholder="Search..."
                        />
                        <div className="d-flex align-items-center gap-2">
                            <label htmlFor="date-from">From: </label>
                            <input
                                type="date"
                                id="date-from"
                                onChange={handleStartDateChange}
                                style={{
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                }}
                            />
                            <label htmlFor="date-to">To: </label>
                            <input
                                type="date"
                                id="date-to"
                                onChange={handleEndDateChange}
                                style={{
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                }}
                            />
                        </div>
                        <button
                            className='btn btn-primary'
                            style={{
                                padding: "10px 20px",
                            }}
                            onClick={handleSearch}
                            disabled={!selectedUser.id}
                        >
                            Search
                        </button>
                    </div>
                    {showInfo ? (<React.Fragment>
                        <div className='' style={{
                            background: "#f8f9fa",
                            padding: "20px",
                            borderRadius: "10px",
                            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                        }}>
                            <h3 className='mb-3'>User Information</h3>
                            <div className='d-flex align-items-center justify-content-between gap-5'>
                                <div>
                                    <div className='d-flex gap-2'><b>Name: </b><span>{`${selectedUser.name}`}</span></div>
                                    <div className='d-flex gap-2'><b>User Name:</b><span>{`${selectedUser.username}`}</span></div>
                                </div>
                                <div>
                                    <div className='d-flex gap-2'><b>Email: </b><span>{`${selectedUser.email}`}</span></div>

                                    <div className='d-flex gap-2'><b>Company: </b><span>{`${selectedUser.company}`}</span></div>
                                </div>
                                <div>
                                    <div className='d-flex gap-2'><b>Phone Number: </b><span>{`${selectedUser.phone_number !== "" ? selectedUser.phone_number : "Not Available"}`}</span></div>
                                    <div className='d-flex gap-2'><b>Shopify Domain: </b><span>{`${selectedUser.shopify_domain === null ? "Not Available" : selectedUser.shopify_domain}`}</span></div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", gap: "20px", flex: 1, marginTop: "20px" }}>
                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "50px",
                                }}
                            >
                                <div
                                    style={{
                                        background: "#f8f9fa",
                                        padding: "20px",
                                        borderRadius: "10px",
                                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                                    }}
                                >
                                    <h3>Generated Items</h3>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between",
                                            marginTop: "10px",
                                            gap: "3px"
                                        }}
                                    >
                                        <div className="d-flex justify-content-between">
                                            <strong>Blogs:</strong> <span>{counts.blog}</span>
                                        </div >
                                        <hr />
                                        <div className="d-flex justify-content-between">
                                            <strong>Social Media:</strong> <span>{counts.social}</span>
                                        </div>
                                        <hr />
                                        <div className="d-flex justify-content-between">
                                            <strong>Newsletter:</strong> <span>{counts.newsletter}</span>
                                        </div>
                                        <hr />
                                        <div className="d-flex justify-content-between">
                                            <strong>Others:</strong> <span>{counts.others}</span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        background: "#f8f9fa",
                                        padding: "20px",
                                        borderRadius: "10px",
                                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                                    }}
                                >
                                    <h3>Credit's Overview</h3>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between",
                                            marginTop: "10px",
                                            gap: "3px"
                                        }}
                                    >
                                        <div className="d-flex justify-content-between">
                                            <strong>Total Credits:</strong> <span>{creditDetails.total}</span>
                                        </div>
                                        <hr />
                                        <div className="d-flex justify-content-between">
                                            <strong>Credits Used:</strong> <span>{creditDetails.used}</span>
                                        </div>
                                        <hr />
                                        {
                                            creditDetails.isOrgUser && (
                                                <React.Fragment>
                                                    <div className="d-flex justify-content-between">
                                                        <strong>Credits Used By This User:</strong> <span>{creditDetails.userUsedCredits || '0'}</span>
                                                    </div>
                                                    <hr />
                                                </React.Fragment>
                                            )
                                        }
                                        <div className="d-flex justify-content-between">
                                            <strong>Credits Remaining:</strong> <span>{creditDetails.remaining}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <UserTransactionHistory selectedUser={selectedUser} startDate={startDate} endDate={endDate} search={search} isOrgUser={isOrgUser}/>
                        </div>
                        <UserLog selectedUser={selectedUser} startDate={startDate} endDate={endDate} search={search} setCreditDetails={setCreditDetails} creditDetails={creditDetails} counts={counts} setCounts={setCounts} isOrgUser={isOrgUser}/>
                    </React.Fragment>) : (<h2 className="d-flex aligh-items-center justify-content-center p-5 m-5">
                        Please search for a user to get information
                    </h2>)}
                </div>
            </div>
        </React.Fragment>
    );
};

const UserTransactionHistory = ({ selectedUser, startDate = "", endDate = "", search, isOrgUser = false}) => {
    const dispatch = useDispatch();
    const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);

    const [transactionList, setTransactionList] = useState([]);
    const [transactionLength, setTransactionLength] = useState(0);

    const handlePageClick = (limit, offset) => {
        if (offset >= 0) {
            handleGetUserTransactions("", limit, offset);
        }
    };

    useEffect(() => {
        if (selectedUser && selectedUser.id) {
            handleGetUserTransactions("", 10, 0);
        }
    }, [search]);

    const handleGetUserTransactions = (type, limit, offset) => {
        dispatch(setLoader(true));
        setTransactionLength(0);
        setTransactionList([]);
        const url = `${apiBaseUrl}/api/user/credit-transactions/${selectedUser.id}?type=${type}&limit=${limit}&offset=${offset}&startDate=${startDate}&endDate=${endDate}`;
        AxiosApi.get(url).then((response) => {
            setTransactionLength(response.data?.length);
            setTransactionList(response.data?.data);
            dispatch(setLoader(false));
        }).catch((error) => {
            dispatch(setLoader(false));
        });
    };

    return (
        <div className="dashboard-history border-radius-12 bg-white">
            <div className="d-flex align-items-center justify-content-between">
                <h4 className="mb-0">User Credit Transaction History ( {transactionLength} )</h4>
            </div>
            <div className="res-table">
                <table className="custom-table transaction-list-table table mt-20 mb-4">
                    <thead className="">
                        <tr>
                            <th scope="col">Type</th>
                            <th scope="col">Sub Type</th>
                            {isOrgUser && <th scope="col">User</th>}
                            <th scope="col">Debit / Credit</th>
                            <th scope="col">Balance</th>
                            <th scope="col">Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactionList && transactionList.length > 0 ? (
                            <React.Fragment>
                                {transactionList.map((item, index) => (
                                    <tr key={index}>
                                        <td className="">{item?.type}</td>
                                        <td className="">{item?.sub_type || "-"}</td>
                                        {isOrgUser && <td className="">{item?.username}</td>}
                                        <td className="">
                                            <div className="d-flex align-items-center">
                                                <span style={{ width: "24px" }}>{item?.credit > 0 ? <BiPlus color='#00ab55' className='fs-5' /> : <BiMinus color='#e7515a' className='fs-5' />}</span>
                                                {" "} {item?.credit ? item.credit : item?.debit}
                                            </div>
                                        </td>
                                        <td className="">{item?.balance}</td>
                                        <td className="">
                                            <h6>{moment(item?.created_at).format("MMM DD YYYY - h:mm a")}</h6>
                                        </td>
                                    </tr>
                                ))}
                            </React.Fragment>
                        ) : (
                            <tr>
                                <td colSpan="5" className="text-center">
                                    <NoDataAvailable />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <TablePaginate length={transactionLength} handlePageChange={handlePageClick} />
        </div>
    );
};

const UserLog = ({ selectedUser, startDate = "", endDate = "", search, counts, setCounts, isOrgUser = false}) => {
    const dispatch = useDispatch();
    const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);
    const [articleHistory, setArticleHistory] = useState([]);
    const [searchTxt, setSearchTxt] = useState("");
    const [selectedType, setSelectedType] = useState('all');
    const [currentPage, setCurrentPage] = useState(0);
    const [categorizedData, setCategorizedData] = useState({
        all: [],
        blog: [],
        social: [],
        newsletter: [],
        others: []
    });

    useEffect(() => {
        if (articleHistory && articleHistory.length > 0) {
            const newCounts = {
                blog: 0,
                social: 0,
                newsletter: 0,
                others: 0
            };

            const newCategorizedData = {
                all: articleHistory,
                blog: [],
                social: [],
                newsletter: [],
                others: []
            };

            articleHistory.forEach(item => {
                const displayType = item?.display_type?.toLowerCase() || '';

                if (displayType.includes('blog')) {
                    newCounts.blog++;
                    newCategorizedData.blog.push(item);
                } else if (displayType.includes('social')) {
                    newCounts.social++;
                    newCategorizedData.social.push(item);
                } else if (displayType.includes('newsletter')) {
                    newCounts.newsletter++;
                    newCategorizedData.newsletter.push(item);
                } else {
                    newCounts.others++;
                    newCategorizedData.others.push(item);
                }
            });

            setCounts(newCounts);
            setCategorizedData(newCategorizedData);
        }
    }, [articleHistory]);

    const filterOptions = [
        { value: 'all', label: `All (${articleHistory?.length || 0})` },
        { value: 'blog', label: `Blog (${counts.blog})` },
        { value: 'social', label: `Social (${counts.social})` },
        { value: 'newsletter', label: `Newsletter (${counts.newsletter})` },
        { value: 'others', label: `Others (${counts.others})` }
    ];
    const ITEMS_PER_PAGE = 10;

    const paginatedData = useMemo(() => {
        const startOffset = currentPage * ITEMS_PER_PAGE;
        const filteredArray = categorizedData[selectedType] || [];
        return filteredArray
            .filter(item => {
                return item?.topic?.toLowerCase().includes(searchTxt.toLowerCase()) ||
                    item?.keyword?.toLowerCase().includes(searchTxt.toLowerCase()) ||
                    item?.AI_type?.toLowerCase().includes(searchTxt.toLowerCase()) ||
                    item?.lan?.toLowerCase().includes(searchTxt.toLowerCase());
            })
            .slice(startOffset, startOffset + ITEMS_PER_PAGE);
    }, [categorizedData, selectedType, searchTxt, currentPage]);

    const totalItems = useMemo(() => {
        const filteredArray = categorizedData[selectedType] || [];
        return filteredArray.filter(item => {
            return item?.topic?.toLowerCase().includes(searchTxt.toLowerCase()) ||
                item?.keyword?.toLowerCase().includes(searchTxt.toLowerCase()) ||
                item?.AI_type?.toLowerCase().includes(searchTxt.toLowerCase()) ||
                item?.lan?.toLowerCase().includes(searchTxt.toLowerCase());
        }).length;
    }, [categorizedData, selectedType, searchTxt]);

    const handlePageClick = (limit, offset) => {
        if (offset >= 0) {
            setCurrentPage(Math.floor(offset / ITEMS_PER_PAGE));
        }
    };

    useEffect(() => {
        setSearchTxt("");
        if (selectedUser && selectedUser.id) {
            handleGetAllArticleHistory(selectedUser.id);
        }
    }, [search]);

    const handleGetAllArticleHistory = async (userId) => {
        try {
            setArticleHistory([]);
            const response = await AxiosApi.get(`${apiBaseUrl}/api/article-post-history/list-by-user/${userId}?startDate=${startDate}&endDate=${endDate}`);
            dispatch(setLoader(false));
            if (response && response.data && response.data.data) {
                setArticleHistory(response.data.data);
            }
        } catch (error) {
            dispatch(setLoader(false));
            toast.error("Something went wrong.");
            console.log(error);
        }
    };

    const topicBodyTemplate = (item) => {
        return (
            <React.Fragment>
                <h6 className="text-width" data-tooltip-id="my-tooltip" data-tooltip-content={item?.topic} data-tooltip-place="bottom">
                    {item?.topic}
                </h6>
                <p className="text-width" data-tooltip-id="my-tooltip" data-tooltip-content={item?.keyword} data-tooltip-place="bottom">
                    {item?.keyword || "-"}
                </p>
            </React.Fragment>
        );
    };

    const statusBodyTemplate = (item) => {
        let backgroundColor = "", status = "";
        if (item.status === "completed") {
            backgroundColor = "#84CC16";
            status = "Done";
        } else if (item.status === "pending" && item.type !== "short_flow") {
            backgroundColor = "#F59E0B";
            status = "In Progress";
        } else if (item.status === "pending") {
            backgroundColor = "#E7E721";
            status = "Processing ...";
        } else if (item.status === "processing") {
            backgroundColor = "#F59E0B";
            status = "In Progress";
        } else if (item.status === "notAcceptable") {
            backgroundColor = "#EF4444";
            status = "Not Acceptable";
        } else {
            backgroundColor = "#EF4444";
            status = "Error";
        }

        return (
            <div className="d-flex align-items-center gap-2">
                <div className="dotStatus">
                    <span className={`d-block`} style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: backgroundColor }} />
                </div> {status}
            </div>
        );
    };

    const actionBodyTemplate = (item) => {
        let articleHref = ``;
        if (item.display_type === "1-Click Blog Writer") {
            articleHref = `/history-details/${item.id}`;
        } else if (item.display_type === "SEO Blog Co-Pilot") {
            articleHref = `/article-optimized-blog/${item.id}`;
        } else if (item.display_type === "Social Media Post Writer") {
            articleHref = `/social-media-post/${item.id}`;
        } else if (item.display_type === "Newsletter Builder") {
            articleHref = `/news-letters/${item.id}`;
        } else if (item.display_type === "Azgo Newsletter") {
            articleHref = `/azgo-newsletter/${item.id}`;
        } else if (item.display_type === "CMI Newsletter") {
            articleHref = `/cmi-newsletter/${item.id}`;
        } else if (item.display_type === "Press Release Quick Draft") {
            articleHref = `/press-release/${item.id}`;
        } else if (item.display_type === "E-Commerce Blog Writer") {
            articleHref = `/e-commerce/${item.id}`;
        } else if (item.display_type === "PDF To Article") {
            articleHref = `/history-details/${item.id}`;
        } else if (item.display_type === "Invest Idea") {
            articleHref = `/invest-idea-history/${item.id}`;
        } else if (item.display_type === "Rysense Blog") {
            articleHref = `/happydot-blog/${item.id}`;
        } else if (item.display_type === "Imda Post Writer") {
            articleHref = `/imda-post/${item.id}`;
        } else if (item.display_type === "HappyDot Blog") {
            articleHref = `/happydot-blog/${item.id}`;
        }

        return (
            <Dropdown align="end" className="actionDropdown" onClick={(e) => e.stopPropagation()}>
                <Dropdown.Toggle id="dropdown-basic">
                    <BsThreeDots />
                </Dropdown.Toggle>
                {articleHref && articleHref !== "" && (
                    <Dropdown.Menu>
                        <Dropdown.Item target="_blank" href={articleHref} rel="noreferrer">
                            View <AiOutlineEye />
                        </Dropdown.Item>
                    </Dropdown.Menu>
                )}
            </Dropdown>
        );
    };

    const handleNavigateToViewArticle = (item) => {
        let articleHref = ``;
        if (item.display_type === "1-Click Blog Writer") {
            articleHref = `/history-details/${item.id}`;
        } else if (item.display_type === "SEO Blog Co-Pilot") {
            articleHref = `/article-optimized-blog/${item.id}`;
        } else if (item.display_type === "Social Media Post Writer") {
            articleHref = `/social-media-post/${item.id}`;
        } else if (item.display_type === "Newsletter Builder") {
            articleHref = `/news-letters/${item.id}`;
        } else if (item.display_type === "Azgo Newsletter") {
            articleHref = `/azgo-newsletter/${item.id}`;
        } else if (item.display_type === "CMI Newsletter") {
            articleHref = `/cmi-newsletter/${item.id}`;
        } else if (item.display_type === "Press Release Quick Draft") {
            articleHref = `/press-release/${item.id}`;
        } else if (item.display_type === "E-Commerce Blog Writer") {
            articleHref = `/e-commerce/${item.id}`;
        } else if (item.display_type === "PDF To Article") {
            articleHref = `/history-details/${item.id}`;
        } else if (item.display_type === "Invest Idea") {
            articleHref = `/invest-idea-history/${item.id}`;
        } else if (item.display_type === "Rysense Blog") {
            articleHref = `/happydot-blog/${item.id}`;
        } else if (item.display_type === "Imda Post Writer") {
            articleHref = `/imda-post/${item.id}`;
        } else if (item.display_type === "HappyDot Blog") {
            articleHref = `/happydot-blog/${item.id}`;
        }

        window.open(articleHref, "_blank");
    };

    return (
        <div className="dashboard-history border-radius-12 bg-white mt-4">
            <div className="d-flex align-items-center justify-content-between">
                <h4 className="mb-0">Article History ( {articleHistory?.length || 0} )</h4>
                <div className="d-flex align-items-center gap-3">
                    <Select
                        options={filterOptions}
                        value={filterOptions.find(option => option.value === selectedType)}
                        onChange={(option) => setSelectedType(option.value)}
                        className="filter-select"
                        styles={{
                            control: (base) => ({
                                ...base,
                                minWidth: '150px',
                                height: '40px'
                            })
                        }}
                    />
                    <div className="input-group" style={{ height: "40px" }}>
                        <input placeholder="Search Name..." type="text" className="form-control" onChange={(e) => setSearchTxt(e.target.value)} />
                    </div>
                </div>
            </div>
            <div className="res-table">
                <DataTable
                    value={paginatedData}
                    className="custom-datatable"
                    emptyMessage={<NoDataAvailable />}
                    selectionMode="single"
                    dataKey="id"
                    onSelectionChange={(e) => handleNavigateToViewArticle(e.value)}
                >
                    <Column header="Id" field="id"></Column>
                    <Column header="Article Details" sortField="topic" body={topicBodyTemplate} sortable></Column>
                    <Column header="Type" sortField="display_type" sortable body={(item) => <div>{item?.display_type || "-"}</div>}></Column>
                    {isOrgUser && <Column header="User" sortField="user_id" sortable body={(item) => <div>{item?.username}</div>}></Column>}
                    <Column header="Last modified" sortField="created_at" sortable body={(item) => moment(item?.created_at).format("MMM DD YYYY - h:mm a")}></Column>
                    <Column header="Ai Type" sortField="AI_type" sortable body={(item) => (item?.AI_type || "-")}></Column>
                    <Column header="status" sortField="status" sortable body={statusBodyTemplate}></Column>
                    <Column header="Language" sortField="lan" sortable body={(item) => <div>{item?.lan}</div>}></Column>
                    <Column header="Action" body={actionBodyTemplate}></Column>
                </DataTable>
            </div>
            <TablePaginate
                length={totalItems}
                handlePageChange={handlePageClick}
                itemsPerPage={ITEMS_PER_PAGE}
            />
        </div>
    );
};

export default CreditUsage;