import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AxiosApi } from "../utility/axios";
import { setLoader } from "../store/reducer";
import { LinkedInPostPreview } from "../Components/PostPreview";

const ImdaPostWriterDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const [postData, setPostData] = useState({});
  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);

  useEffect(() => {
    if (params?.id) {
      dispatch(setLoader(true));
      AxiosApi.get(`${apiBaseUrl}/api/post-writer/postHistory/byId/${params?.id}?role=admin&type=imda`).then(async (res) => {
        setPostData(res.data.data);
        dispatch(setLoader(false));
      }).catch((error) => {
        console.log(error);
        dispatch(setLoader(false));
      });
    }
  }, [params]);

  return (
    <div className="w-100">
      <div className="border-radius-12 bg-white AIWriterBlog">
        <div className="AIWriterBlogHead">
          <div className="AIWriterBlogHeadTop">
            <h3>IMDA Post Writer - {postData.post_type || "LinkedIn"} Post</h3>
          </div>
        </div>
        <div className="addllyFormWrap">
          <form>
            <Row>
              {postData?.insert_link ? (
                <Col sm="12" md="6">
                  <div className="form-group mb-4">
                    <label>Need a post crafted around your website link?</label>
                    <input
                      name="link" type="text" placeholder="Insert your link here and let's transform it into a compelling social media post!"
                      defaultValue={postData?.insert_link} className={`addllyForm-control`} disabled
                    />
                  </div>
                </Col>
              ) : (
                <React.Fragment>
                  <Col sm="12" md="12">
                    <div className="form-group mb-4">
                      <label>
                        {postData && postData.post_type === "Instagram" ? (
                          "What do you want to write about? Write a brief description of your photo or topic."
                        ) : (
                          "What do you want to write about? Write a brief description."
                        )}
                      </label>
                      <textarea
                        name="topic" type="text" rows="3" defaultValue={postData?.topic}
                        placeholder={postData?.post_type === "Instagram" ? "Example: First time trying..." : "Example: Best way to get the most out of..."}
                        className={`addllyForm-control`} style={{ borderRadius: "25px" }} disabled
                      />
                    </div>
                  </Col>
                  <Col sm="12" md="6">
                    <div className="form-group mb-4">
                      <label>One main, most relevant hashtag</label>
                      <input
                        name="keyword" type="text" placeholder="Example: Google"
                        className={`addllyForm-control`} disabled defaultValue={postData?.keyword}
                      />
                    </div>
                  </Col>
                </React.Fragment>
              )}
              <Col sm="12" md="6">
                <div className="form-group mb-4">
                  <label>Selected Type</label>
                  <input
                    name="keyword" type="text" placeholder="Selected Type" className={`addllyForm-control`}
                    defaultValue={postData?.type || ""} disabled
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col xl={12}>
                <div className="smartWriterTab">
                  <Tabs defaultActiveKey={"generatedPosts"} className="mb-3">
                    {postData && postData.sets_of_post && postData.sets_of_post !== "" && (
                      <Tab eventKey="generatedPosts" title="Generated Posts">
                        <Row className="mt-4">
                          {postData.sets_of_post && JSON.parse(postData.sets_of_post)?.map((item, index) => (
                            <Col sm={12} md={6} lg={4} key={index}>
                              <div className={`media-post-content ${postData?.selected_post === item ? "active" : ""}`}>
                                <pre>{item}</pre>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </Tab>
                    )}
                    {postData && postData.final_post && postData.final_post !== "" && (
                      <Tab eventKey="finalPost" title="Final Post and Preview">
                        <Row className="mt-4">
                          <Col sm={postData?.final_post ? 8 : 12}>
                            <div className="socialPostEditor">
                              <textarea
                                className="w-100 outlineTextarea" value={postData?.final_post} style={{ height: "60vh" }} disabled
                              />
                            </div>
                          </Col>
                          <Col sm={4}>
                            <LinkedInPostPreview
                              post={postData?.final_post} col={12} isUploadImage={false} setUploadFileModal={() => { }}
                              preview_img={postData?.post_image && JSON.parse(postData?.post_image)?.data ? JSON.parse(postData.post_image).data : ""}
                            />
                          </Col>
                        </Row>
                      </Tab>
                    )}
                  </Tabs>
                </div>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ImdaPostWriterDetails;
